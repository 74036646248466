import fly from "flyio";
import EngineWrapper from "flyio/dist/npm/engine-wrapper"
import vc from "../../../common/core";
import router from "@/router";
import { STORAGE_KEY } from "@/constant";
import OSS from "ali-oss";
import dayjs from "dayjs"
import Vue from '../../main';
const HttpMethod = {
    POST: "POST",
    GET: "GET",
};

const HttpStatus = {
    OK: 200,
};
// pc端的appId
const PC_X_APP_ID = "web-dashboard";
/* const baseURL = "https://api.cosmos-property.com";
fly.config.baseURL = baseURL; */
/* fly.config.withCredentials = true;
fly.engine.withCredentials = true; */
const requestRecord = {};

if (fetch) {
    fly.engine = EngineWrapper(function (request, responseCallback) {
        let { url, method, headers, timeout, body, responseType } = request;
        let requestController = new AbortController();
        let signal = requestController.signal;
        timeout = timeout || 1000 * 600;
        method = method.toUpperCase();
        const params = {
            body,
            headers,
            method
        }
        if (method === 'GET') {
            delete params.body;
        }
        Promise.race([
            fetch(url, { signal, ...params }),
            new Promise((resolve, reject) => {
                setTimeout(reject, timeout, 'timeout')
            })
        ])
            .then(async res => {
                let responseText = '';
                try {
                    switch (responseType) {
                        case 'arraybuffer':
                            responseText = await res.blob()
                            break;
                        case 'blob':
                            responseText = res.blob();
                            break;
                        default:
                            responseText = await res.json()
                    }
                } catch (err) {
                    responseText = res.statusText;
                }
                responseCallback({
                    responseText,
                    statusCode: res.status,
                    errMsg: res.statusText,
                    headers: res.headers,
                });
            })
            .catch(err => {
                requestController.abort();
                responseCallback({
                    responseText: 'timeout',
                    statusCode: 500,
                    errMsg: 'timeout',
                    headers,
                });
            })
    });
}

fly.interceptors.request.use((request) => {
    Vue.$vc.emit('startLoading', {});
    let customHeaders = {
        "x-app-id": PC_X_APP_ID,
        "x-region-code": vc.getCurrentRegion()?.code,
        [STORAGE_KEY.X_TOKEN]: localStorage.getItem(STORAGE_KEY.X_TOKEN) || sessionStorage.getItem(STORAGE_KEY.X_TOKEN),
    };
    request.headers = {
        ...request.headers,
        ...vc.removeNullProperty(customHeaders),
    };
    if (requestRecord[request.url] && request.headers.isForm) {
        return Promise.reject('拦截请求');
    }
    requestRecord[request.url] = true;
    return request;
});

fly.interceptors.response.use(
    (response) => {
        Vue.$vc.emit('loading', {});
        requestRecord[response.request.url] = false;
        let { request, data } = response;
        if (request.headers.notParse) {
            return data;
        }
        if (typeof data === "string") {
            data = JSON.parse(data);
        }
        if (response.status === HttpStatus.OK && data.code !== 0) {
            const { headers } = request;
            // 有些错误信息需要到具体业务实现处进行处理, headers中携带notErrMsg就认为是有需要特殊处理的
            if (headers.notErrMsg && headers.notErrMsg.codes.includes(data.code)) {
                return data;
            } else {
                vc.message(data.msg);
            }
        }
        return data;
    },
    err => {
        requestRecord[err.request.url] = false;
        const errMsg = '错误码：' + err.status + ';';
        switch (err.status.toString().charAt(0)) {
            case '4':
            case '5':
                if (err.status === 403) {
                    router.push({ name: "defaultPage" });
                    return;
                }
                vc.message("服务器异常，请稍后再试");
                return;
            default:
                try {
                    vc.message(errMsg + (typeof err === "string" ? err : JSON.stringify(err)));
                } catch (e) {
                    vc.message(errMsg + e);
                }
        }
    }
);

// 上传文件到阿里服务器
async function putFile(file, callback = (ossObject) => { console.log(ossObject, '进度对象') }, ossKey = "/account/staff/ossKey") {
    let tempCheckpoint, // 断点续传
        checkpointCount = 0; // 断点上传的次数
    let res = await fly.get(ossKey);
    let ossKeyData = res.data;
    const ossConfig = {
        region: 'oss-cn-beijing',
        accessKeyId: ossKeyData.accessKeyId,
        accessKeySecret: ossKeyData.accessKeySecret,
        bucket: 'cosmoss-img',
        stsToken: ossKeyData.token,
    },
        client = new OSS(ossConfig);
    try {
        const fileName = dayjs().format("YYYY-MM-DD") + '/' + file.name;
        if (file.size / 1024 / 1024 < 10) {
            return client.put(fileName, file).then(res => {
                return {
                    ...res,
                    fileName: file.name,
                }
            });
        } else {
            return multipartUpload(ossConfig, file, tempCheckpoint, client, checkpointCount, callback).then(res => res);
        }
    } catch (e) {
        throw new Error(e);
    }
}

/**
 * 分片上传
 * @param {Object} ossConfig 阿里云oss配置
 * @param {object} file 上传的文件对象
 * @param {Object} tempCheckpoint 断点记录
 * @param {Object} ossClient 阿里云上传对象
 * @param {Number} checkpointCount 断点上传的次数
 * @param {Function} callback 返回上传进度和上传对象
 * @param {Number} maxCheckpointCount 断点上传的最大次数限制
 * */
function multipartUpload(ossConfig, file, tempCheckpoint, ossClient, checkpointCount, callback, maxCheckpointCount = 20) {
    let fileName = dayjs().format("YYYY-MM-DD") + '/';
    file.name.split('.').forEach((str, i) => {
        if (i < file.name.split('.').length - 1) {
            fileName += file.needEncodeURI ? encodeURI(str) : str;
            return;
        }
        if (i > 0) {
            fileName += '.' + str;
        }
    })

    let isTerminationUpload = false;
    return new Promise(resolve => {
        ossClient.multipartUpload(fileName, file, {
            timeout: 120000,
            progress: function (p, checkpoint) {
                tempCheckpoint = checkpoint;
                ossClient.terminationUpload = function () {
                    ossClient.cancel();
                    ossClient = null;
                    isTerminationUpload = true;
                };
                callback({
                    ossClient,
                    progress: (p * 100).toFixed(2),
                });
            },
            mime: file.type,
        })
            .then(res => {
                console.log(res, '大文件上传结果');
                resolve({
                    ...res.res,
                    fileName: file.name,
                    url: res.res.requestUrls[0].split('?')[0],
                });
            })
            .catch(() => {
                if (isTerminationUpload) {
                    return;
                }
                if (checkpointCount >= maxCheckpointCount) {
                    resolve({
                        err: '已达重新上传的上限'
                    })
                    return;
                }
                checkpointCount++;
                let resumeClient = new OSS(ossConfig);
                return multipartUpload(resumeClient, file, tempCheckpoint, resumeClient, checkpointCount, callback, maxCheckpointCount);
            })
    })
}

/**
 * 读取文件
 * @param {Blob | File} res 文件对象
 * */
function readFile(res) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = function (e) {
            resolve(e.target.result)
        };
    })
}

/**
 * 读取文件流
 * @param {String} method 请求的方法
 * @param {String} url 请求的资源地址
 * @param {Object} data 请求的参数
 * @param {Object} config 请求的配置
 * */
fly.readFileStream = function ({
    method = 'get',
    url,
    data = {},
    config = {},
}) {
    let originConfig = {
        responseType: "blob",
        headers: {
            'Content-Type': 'application/x-download',
            notParse: true,
        },
    };
    for (let key in originConfig) {
        if (config.hasOwnProperty(key)) {
            config[key] = {
                ...originConfig[key],
                ...config[key],
            };
        } else {
            config[key] = originConfig[key];
        }
    }


    return fly[method](url, data, config)
        .then(async res => {
            return await readFile(res);
        })
}



fly.putFile = putFile;

fly.upload = function (uploadPhotoUrl, params, config = {
    headers: {
        "Content-Type": "multipart/form-data"
    }
}) {
    return fly.post(uploadPhotoUrl, params, config).then(res => res)
        .catch(err => err)
}


function loadXML(xmlString) { //构建xmldoc对象
    let xmlDoc = null,
        domParser;
    if (window.DOMParser) {
        try {
            domParser = new DOMParser();
            xmlDoc = domParser.parseFromString(xmlString, 'text/xml');
        } catch (e) {
            throw new Error(e);
        }
    } else if (!window.DOMParser && window.ActiveXObject) { //window.DOMParser 判断是否是非ie浏览器
        let xmlDomVersions = ['MSXML2.DOMDocument', 'Microsoft.XMLDOM'];
        for (let i = 0; i < xmlDomVersions.length; i++) {
            try {
                xmlDoc = new ActiveXObject(xmlDomVersions[i]);
                xmlDoc.async = false;
                xmlDoc.loadXML(xmlString); //loadXML方法载入xml字符串
                break;
            } catch (e) {
                continue;
            }
        }
    } else {
        return null;
    }
    return xmlDoc;
}

export default fly;