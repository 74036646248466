// 添加摄像头
const addCameraUrl = '/monitor/camera/save';
// 删除摄像头
const deleteCameraUrl = '/monitor/camera/delete';
// 获取摄像头之前设置的数据
const queryCameraConfigUrl = '/monitor/camera/get';
// 查询监控摄像头
const queryCameraListUrl = '/monitor/camera/queryCameraList';
// 查询统计
const queryTotalUrl = '/monitor/ftp/file/log';
// 查询监控列表(添加监控组、人流统计、监控录像)页面使用
const queryCameraListFilterUrl = '/monitor/camera/queryCameraByRegionCode';
// 添加监控组
const addCameraGroupUrl = '/monitor/cameraGroup/save';
// 修改监控摄像头
const editCameraConfigUrl = '/monitor/camera/update';
// 查询监控组列表
const queryCameraGroupUrl = '/monitor/cameraGroup/queryCameraGroup';
// 查询抓拍的人脸差异记录
const queryFaceDiffLogUrl = '/monitor/contrastLog/query';
// 删除监控组
const deleteCameraGroupUrl = '/monitor/cameraGroup/delete';
// 修改监控组
const editCameraGroupUrl = '/monitor/cameraGroup/update';
// 启用监控检测项
const enableDetectionUrl = '/monitor/camera/enableDetection';
// 查询异常抓拍
const queryCapturePhotoAbnormalUrl = '/monitor/capturePhotoAbnormal/queryCapturePhotoAbnormal';
// 保存工单推送设置
const saveAbnormalPushConfigUrl = '/monitor/abnormalPushConfig/saveOrUpdate'
// 查询工单推送配置
const queryAbnormalPushConfigUrl = '/monitor/abnormalPushConfig/getByRegionCode';

// 添加关注人接口
const saveAttentionUserUrl = '/monitor/faceData/addAttention';
// 删除关注人接口
const deleteAttentionUserUrl = '/monitor/faceData/deleteFaceDataById';
// 单个摄像头的异常抓拍
const queryCameraCaptureAbnormalInfoUrl = '/monitor/camera/queryCameraCaptureAbnormalInfo';
// 单个摄像头非异常抓拍
const queryCameraCaptureInfoUrl = '/monitor/camera/queryCameraCaptureInfo';
// 查询关注人列表
const queryFaceDataUrl = '/monitor/faceData/queryFaceData';
// 查询关注人档案接口
const queryFileByIdUrl = '/monitor/faceData/queryFileById';
// 关注人行动轨迹
const queryMovementUrl = '/monitor/faceData/queryMovement';
// 编辑关注人
const editFaceDataUrl = '/monitor/faceData/editFaceData';
// 查询异常未处理数量
const queryUntreatedCountUrl = '/monitor/camera/queryUntreatedCount';
// 获取视频流地址
const openRealTimeStreamUrl = '/monitor/camera/openRealTimeStream';
// 关闭视频流
const closeRealTimeStreamUrl = '/monitor/camera/closeRealTimeStream';
// 监控最新抓取的人脸
const queryCaptureNewFacePhotoUrl = '/monitor/camera/queryCameraPhoto';
// 查询抓拍记录
const queryCapturePhotoLogUrl = '/monitor/capturePhoto/queryCapturePhoto';
// 查询人流量
const queryCameraPeopleCountUrl = '/monitor/camera/queryCameraPeopleCount';
// 查看单个监控启用的监测配置
const queryEnableDetectionCountUrl = '/monitor/camera/queryEnableDetectionCount';
// 单个监控设置检测项
const setMonitorByConditionUrl = '/monitor/camera/setMonitorByCondition';
// 获取单个监控设置检测项(回显)
const getMonitorByConditionUrl = '/monitor/camera/getCameraEnable';
// 上传图片snapshot接口
const uploadSnapshotImageUrl = '/account/user/uploadSnapshotImage';
// 查询人脸库数量
const getFaceDbNumUrl = '/monitor/faceData/getFaceDbNum';
// 查询删除人脸数据
const queryRemoveFaceDataUrl = '/monitor/faceData/queryDeleteFace';
// 查询删除人脸规则
const queryRemoveFaceRuleUrl = '/monitor/rule/adminQuery';
// 更新删除人脸规则
const updateRemoveFaceRuleUrl = '/monitor/rule/update';

// 查询盒子列表
const queryAiBox = '/monitor/aiBox/queryAiBox';
// 添加盒子接口
const addAiBox = '/monitor/aiBox/addAiBox';
//删除盒子接口
const deleteAiBox = '/monitor/aiBox/deleteAiBox';
//修改盒子接口
const updateAiBox = '/monitor/aiBox/editAiBox';
//查询盒子列表 添加监控使用
const queryAiBoxList = '/monitor/aiBox/queryAiBoxList';
//订阅盒子发送的信息
const startBox = '/monitor/aiBox/startBox';


//单个监控设置人员聚集
const enablePeopleGathering = '/monitor/camera/enablePeopleGathering';
const snapImgHost = 'https://cosmoss-snapshot.oss-cn-shanghai.aliyuncs.com/';
export {
    addCameraUrl,
    deleteCameraUrl,
    queryCameraListUrl,
    addCameraGroupUrl,
    queryCameraConfigUrl,
    editCameraConfigUrl,
    queryCameraGroupUrl,
    deleteCameraGroupUrl,
    editCameraGroupUrl,
    enableDetectionUrl,
    queryCapturePhotoAbnormalUrl,
    saveAbnormalPushConfigUrl,
    queryAbnormalPushConfigUrl,
    saveAttentionUserUrl,
    deleteAttentionUserUrl,
    queryCameraCaptureAbnormalInfoUrl,
    queryCameraCaptureInfoUrl,
    queryFaceDataUrl,
    queryFileByIdUrl,
    queryMovementUrl,
    editFaceDataUrl,
    queryUntreatedCountUrl,
    openRealTimeStreamUrl,
    closeRealTimeStreamUrl,
    queryCaptureNewFacePhotoUrl,
    queryCapturePhotoLogUrl,
    queryCameraPeopleCountUrl,
    queryEnableDetectionCountUrl,
    setMonitorByConditionUrl,
    getMonitorByConditionUrl,
    queryCameraListFilterUrl,
    uploadSnapshotImageUrl,
    snapImgHost,
    queryFaceDiffLogUrl,
    getFaceDbNumUrl,
    queryAiBox,
    addAiBox,
    deleteAiBox,
    updateAiBox,
    queryAiBoxList,
    enablePeopleGathering,
    startBox,
    queryRemoveFaceDataUrl,
    queryRemoveFaceRuleUrl,
    updateRemoveFaceRuleUrl,
    queryTotalUrl,
}
